import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  IconButton,
  IconButtonProps,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { EditorState, Entity, Modifier, SelectionState } from 'draft-js';
import * as React from 'react';

import { SubtleCheckbox } from '@/imports/ui/chakra/components/SubtleCheckbox';
import { LinkIcon } from '@/imports/ui/chakra/feather';
import { useFeatureFlags } from '@/imports/ui/hooks/useFeatureFlags';
import {
  DraftjsEntityType,
  editorStateSettingLink,
} from '@/modules/draft-js-link-plugin';
import { getExternalUrl } from '@/utilities/getExternalUrl';

export const AddLinkButton: React.FC<
  {
    editorState: EditorState;
    setEditorState: (editorState: EditorState) => void;
    submitButtonColorScheme?: string;
  } & IconButtonProps
> = ({ editorState, setEditorState, submitButtonColorScheme, ...props }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);

  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  const selectedText = currentContentBlock.getText().slice(start, end);

  const selectionStartEntityKey = currentContentBlock.getEntityAt(start);
  const selectionStartEntityType =
    selectionStartEntityKey &&
    currentContent.getEntity(selectionStartEntityKey).getType();
  const isActive = selectionStartEntityType === DraftjsEntityType.LINK;

  const [linkText, setLinkText] = React.useState(selectedText);
  const [linkTarget, setLinkTarget] = React.useState('');
  const cleanedLinkTarget = getExternalUrl(linkTarget);

  const { setReferrerPolicy: referrerPolicyFeatureFlag } = useFeatureFlags();
  const [hasReferrerPolicy, setHasReferrerPolicy] = useBoolean(false);

  React.useEffect(() => {
    setLinkText(selectedText);
  }, [selectedText]);

  const onPopoverClose = (): void => {
    setLinkText('');
    setLinkTarget('');
    onClose();
  };

  const onRemoveLink = (): void => {
    let localEditorState = editorState;
    currentContentBlock.findEntityRanges(
      (char) => {
        const entityKey = char.getEntity();
        const entity = entityKey && Entity.get(entityKey);
        return !!entity && entity.getType() === DraftjsEntityType.LINK;
      },
      (entityStart, entityEnd) => {
        if (
          selectionState.isCollapsed() &&
          entityStart <= start &&
          entityEnd >= start
        ) {
          const entityRange = new SelectionState({
            anchorOffset: entityStart,
            anchorKey: currentContentBlock.getKey(),
            focusOffset: entityEnd,
            focusKey: currentContentBlock.getKey(),
            isBackward: false,
            hasFocus: false,
          });

          localEditorState = EditorState.set(localEditorState, {
            currentContent: Modifier.applyEntity(
              localEditorState.getCurrentContent(),
              entityRange,
              null
            ),
          });
        } else {
          localEditorState = EditorState.set(localEditorState, {
            currentContent: Modifier.applyEntity(
              localEditorState.getCurrentContent(),
              selectionState,
              null
            ),
          });
        }
      }
    );

    localEditorState = EditorState.forceSelection(
      localEditorState,
      new SelectionState({
        anchorOffset: end,
        anchorKey: anchorKey,
        focusOffset: end,
        focusKey: anchorKey,
        isBackward: false,
        hasFocus: false,
      })
    );
    setEditorState(localEditorState);
  };

  const onAddLink = (): void => {
    if (!cleanedLinkTarget) {
      return;
    }

    const entityRange = new SelectionState({
      anchorOffset: start,
      anchorKey: anchorKey,
      focusOffset: end,
      focusKey: anchorKey,
      isBackward: false,
      hasFocus: false,
    });

    const newEditorState = editorStateSettingLink(
      editorState,
      entityRange,
      {
        explicit: true,
        url: cleanedLinkTarget,
        targetOption: '_blank',
        hideLinkPreview: false,
        referrerPolicy: hasReferrerPolicy
          ? 'no-referrer-when-downgrade'
          : undefined,
      },
      linkText
    );

    const editorStateFocused = EditorState.forceSelection(
      newEditorState,
      new SelectionState({
        anchorOffset: start + linkText.length,
        anchorKey: anchorKey,
        focusOffset: start + linkText.length,
        focusKey: anchorKey,
        isBackward: false,
        hasFocus: false,
      })
    );

    setEditorState(editorStateFocused);
    onPopoverClose();
  };

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={firstFieldRef}
      onOpen={onOpen}
      onClose={onPopoverClose}
    >
      <PopoverTrigger>
        <IconButton
          isActive={isActive}
          icon={<LinkIcon />}
          onClick={(event) => {
            if (isActive) {
              onRemoveLink();
              event.preventDefault();
            }
          }}
          {...props}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="auto">
          <PopoverArrow />
          <PopoverBody p="4">
            <Stack spacing="4">
              <FormControl>
                <FormLabel>Link Text</FormLabel>
                <Input
                  value={linkText}
                  onChange={(event) => setLinkText(event.target.value)}
                  ref={!selectedText ? firstFieldRef : undefined}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Link Target</FormLabel>
                <Input
                  value={linkTarget}
                  onChange={(event) => setLinkTarget(event.target.value)}
                  ref={selectedText ? firstFieldRef : undefined}
                />
              </FormControl>
              {referrerPolicyFeatureFlag && (
                <FormControl>
                  <SubtleCheckbox
                    isChecked={hasReferrerPolicy}
                    onChange={() => setHasReferrerPolicy.toggle()}
                  >
                    Set Referrer Policy
                  </SubtleCheckbox>
                </FormControl>
              )}
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Button
                  variant="outline"
                  colorScheme="gray"
                  onClick={onPopoverClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={onAddLink}
                  isDisabled={!linkText || !cleanedLinkTarget}
                  // not ideal but i don't want to try and duplicate this whole component just for this one colorscheme change
                  colorScheme={submitButtonColorScheme}
                >
                  Add
                </Button>
              </ButtonGroup>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
