import { BoxProps, chakra } from '@chakra-ui/react';

export const Card = chakra('div', {
  baseStyle: {
    borderRadius: 'xl',
    backgroundColor: 'white',
    shadow: 'base',
  },
});

export type CardProps = BoxProps;
