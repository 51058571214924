import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormLabel,
  Stack,
  useFormControlContext,
} from '@chakra-ui/react';
import * as React from 'react';

export const SubtleCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, isDisabled, ...props }, ref) => {
    const formControlContext = useFormControlContext();
    const areAnyDisabled = formControlContext?.isDisabled || isDisabled;

    return (
      <FormControl isDisabled={areAnyDisabled}>
        <Stack direction="row" alignItems="center">
          <Checkbox {...props} ref={ref} />
          <FormLabel mb="0">{children}</FormLabel>
        </Stack>
      </FormControl>
    );
  }
);

SubtleCheckbox.displayName = 'SubtleCheckbox';
