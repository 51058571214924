import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as React from 'react';

import { logError } from '@/imports/logging/ClientLogger';

const NUM_RETRIES = 3;
const RETRY_SCALAR_MS = 2000;

const axiosWithRetry = axios.create();

axiosRetry(axiosWithRetry, {
  retries: NUM_RETRIES,
  retryDelay: (retryCount) => {
    return retryCount * RETRY_SCALAR_MS;
  },
});

export type IUseUploadFileToS3Props =
  | {
      onUploadStart?: () => void;
      onUploadComplete?: () => void;
    }
  | undefined;

export type IUseUploadFileToS3ResponseState = {
  isUploading: boolean;
  numUploading: number;
  isCanceled: boolean;
  uploadProgress: number | undefined;
  error: Error | undefined;
  cancelUpload: (() => void) | undefined;
};

export type IUploadFileToS3Function = (params: {
  file: File;
  presignedS3Url: string;
  metadataHeaders?: { [key: string]: string };
}) => Promise<void>;

type IUseUploadFileToS3Response = [
  IUploadFileToS3Function,
  IUseUploadFileToS3ResponseState
];

const CancelToken = axios.CancelToken;

export function useUploadFileToS3(
  params: IUseUploadFileToS3Props = {}
): IUseUploadFileToS3Response {
  const toast = useToast();
  const { onUploadStart, onUploadComplete } = params;

  const [isUploading, setIsUploading] =
    React.useState<IUseUploadFileToS3ResponseState['isUploading']>(false);
  const [isCanceled, setIsCanceled] =
    React.useState<IUseUploadFileToS3ResponseState['isCanceled']>(false);
  const [uploadProgress, setUploadProgress] =
    React.useState<IUseUploadFileToS3ResponseState['uploadProgress']>(
      undefined
    );
  const [error, setError] =
    React.useState<IUseUploadFileToS3ResponseState['error']>(undefined);
  const [numUploading, setNumUploading] = React.useState(0);
  let cancelUpload;

  const resetHookState = (): void => {
    setIsUploading(false);
    setNumUploading((numUploading) => numUploading - 1);
    setIsCanceled(false);
    setUploadProgress(undefined);
    setError(undefined);
    cancelUpload = undefined;
  };

  const uploadFileToS3: IUploadFileToS3Function = async function ({
    file,
    presignedS3Url,
    metadataHeaders = {},
  }) {
    try {
      setIsUploading(true);
      setNumUploading((numUploading) => numUploading + 1);
      setUploadProgress(0);
      onUploadStart?.();

      await axiosWithRetry
        .put(presignedS3Url, file, {
          headers: {
            ...metadataHeaders,
            'Content-Type':
              'Content-Type' in metadataHeaders
                ? metadataHeaders['Content-Type']
                : '', //for some reason axios automatically adds a content-type, which breaks the pre-signed URL
          },
          onUploadProgress: (p) => {
            setUploadProgress((p.loaded / p.total) * 100);
          },
          cancelToken: new CancelToken(function executor(cancelRequest) {
            cancelUpload = (): void => {
              cancelRequest();
              resetHookState();
              setIsCanceled(true);
            };
          }),
        })
        .then(() => {
          resetHookState();
          setUploadProgress(100);
          onUploadComplete?.();
        });
    } catch (error) {
      logError(error);

      if (!axios.isCancel(error)) {
        resetHookState();
        setError(error);
        toast({
          title: `An error occurred while uploading your file, please try again.`,
          status: 'error',
          duration: null,
          isClosable: true,
        });

        throw error;
      }
    }
  };

  return [
    uploadFileToS3,
    {
      isUploading,
      isCanceled,
      uploadProgress,
      error,
      cancelUpload,
      numUploading,
    },
  ];
}
