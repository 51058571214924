import { Link } from '@chakra-ui/react';
import {
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  RawDraftEntity,
  RichUtils,
  SelectionState,
} from 'draft-js';
import linkifyIt from 'linkify-it';
import * as React from 'react';

import { RawDraftContentStateFragment } from '@/fragments/graphql-types/RawDraftContentStateFragment';

export { AddLinkButton } from './AddLinkButton';

function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === DraftjsEntityType.LINK
    );
  }, callback);
}

const EditorLink: React.FC<{
  entityKey: string;
  children?: [];
  contentState: ContentState;
}> = (props) => {
  const { children, entityKey, contentState } = props;
  const { url, targetOption } = contentState.getEntity(entityKey).getData();

  return (
    <Link href={url} target={targetOption}>
      {children}
    </Link>
  );
};

const LinkDecorator = {
  strategy: findLinkEntities,
  component: EditorLink,
};

export enum DraftjsEntityType {
  MENTION = 'mention',
  LINK = 'LINK',
}

type ILink = [ILinkMetaData, ILinkPreview];

type ILinkMetaData = {
  key: string;
  hideLinkPreview?: boolean;
  referrerPolicy?: React.HTMLAttributeReferrerPolicy;
};

export type ILinkPreview = {
  url: string;
  targetOption: string;
};

const linkify = linkifyIt();

/**
 * Code is from https://github.com/fedorovsky/draft-js-link-detection-plugin
 * which I would have used instead but React Draft doesn't support plugins
 */

/*
Returns editor state with a link entity created / updated to hold the link @data
for the range specified by @selection
*/

export function editorStateSettingLink(
  editorState: EditorState,
  selection: SelectionState,
  data: {
    explicit: boolean;
    url: string | null;
    targetOption: string;
    hideLinkPreview: boolean | undefined;
    referrerPolicy?: React.HTMLAttributeReferrerPolicy;
  },
  replaceText?: string
): EditorState {
  const contentState = editorState.getCurrentContent();
  const entityKey = getCurrentLinkEntityKey(editorState);

  let nextEditorState = editorState;

  if (!entityKey) {
    const contentStateWithEntity = contentState.createEntity(
      DraftjsEntityType.LINK,
      'MUTABLE',
      data
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    nextEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    nextEditorState = RichUtils.toggleLink(
      nextEditorState,
      selection,
      entityKey
    );

    if (replaceText) {
      const contentStateWithReplacedText = Modifier.replaceText(
        contentState,
        selection,
        replaceText,
        nextEditorState.getCurrentInlineStyle(),
        entityKey
      );
      nextEditorState = EditorState.set(nextEditorState, {
        currentContent: contentStateWithReplacedText,
      });
    }
  } else {
    nextEditorState = EditorState.set(editorState, {
      currentContent: editorState
        .getCurrentContent()
        .replaceEntityData(entityKey, data),
    });
    nextEditorState = EditorState.forceSelection(
      nextEditorState,
      editorState.getSelection()
    );
  }

  return nextEditorState;
}

/*
Returns the entityKey for the link entity the user is currently within.
*/
function getCurrentLinkEntityKey(editorState: EditorState): string | null {
  const contentState = editorState.getCurrentContent();
  const startKey = editorState.getSelection().getStartKey();
  const startOffset = editorState.getSelection().getStartOffset();
  const block = contentState.getBlockForKey(startKey);

  const linkKey = block.getEntityAt(
    Math.min(block.getText().length - 1, startOffset)
  );

  if (linkKey) {
    const linkInstance = contentState.getEntity(linkKey);
    if (linkInstance.getType() === DraftjsEntityType.LINK) {
      return linkKey;
    }
  }
  return null;
}

export const handleEditorStateChange = (
  editorState: EditorState
): EditorState => {
  // Returns the current contents of the editor.
  const contentState = editorState.getCurrentContent();

  // Returns the current cursor/selection state of the editor.
  const selection = editorState.getSelection();

  if (!selection || !selection.isCollapsed()) {
    return editorState;
  }

  const cursorOffset = selection.getStartOffset();
  const cursorBlockKey = selection.getStartKey();
  const cursorBlock = contentState.getBlockForKey(cursorBlockKey);

  if (cursorBlock.getType() !== 'unstyled') {
    return editorState;
  }

  // Step 1: Get the word around the cursor by splitting the current block's text
  const text = cursorBlock.getText();
  const currentWordStart = text.lastIndexOf(' ', cursorOffset) + 1;
  let currentWordEnd = text.indexOf(' ', cursorOffset);
  if (currentWordEnd === -1) {
    currentWordEnd = text.length;
  }

  const currentWord = text.substr(
    currentWordStart,
    currentWordEnd - currentWordStart
  );

  const currentWordIsURL = !!linkify.match(currentWord);
  const matchLinkList = linkify.match(text);
  const url =
    (matchLinkList &&
      matchLinkList.find((match) => match.index === currentWordStart)?.url) ||
    null;

  // Step 2: Find the existing LINK entity under the user's cursor
  let currentLinkEntityKey = cursorBlock.getEntityAt(
    Math.min(text.length - 1, cursorOffset)
  );
  const inst =
    currentLinkEntityKey && contentState.getEntity(currentLinkEntityKey);
  if (inst && inst.getType() !== DraftjsEntityType.LINK) {
    currentLinkEntityKey = '';
  }

  if (currentLinkEntityKey) {
    // Note: we don't touch link values added / removed "explicitly" via the link
    // toolbar button. This means you can make a link with text that doesn't match the link.
    const entityExistingData = contentState
      .getEntity(currentLinkEntityKey)
      .getData();
    if (entityExistingData.explicit) {
      return editorState;
    }

    if (currentWordIsURL) {
      // We are modifying the URL - update the entity to reflect the current text
      const contentState = editorState.getCurrentContent();
      const link = findFirstLinkInEditor(editorState);

      const hideLinkPreview = link ? link[0].hideLinkPreview : undefined;
      const referrerPolicy = link ? link[0].referrerPolicy : undefined;

      return EditorState.set(editorState, {
        currentContent: contentState.replaceEntityData(currentLinkEntityKey, {
          explicit: false,
          url,
          targetOption: '_blank',
          hideLinkPreview,
          referrerPolicy,
        }),
      });
    } else {
      // We are no longer in a URL but the entity is still present. Remove it from
      // the current character so the linkifying "ends".
      const entityRange = new SelectionState({
        anchorOffset: currentWordStart - 1,
        anchorKey: cursorBlockKey,
        focusOffset: currentWordStart,
        focusKey: cursorBlockKey,
        isBackward: false,
        hasFocus: true,
      });

      return EditorState.set(editorState, {
        currentContent: Modifier.applyEntity(
          editorState.getCurrentContent(),
          entityRange,
          null
        ),
      });
    }
  }

  // There is no entity beneath the current word, but it looks like a URL. Linkify it!
  if (!currentLinkEntityKey && currentWordIsURL) {
    const entityRange = new SelectionState({
      anchorOffset: currentWordStart,
      anchorKey: cursorBlockKey,
      focusOffset: currentWordEnd,
      focusKey: cursorBlockKey,
      isBackward: false,
      hasFocus: false,
    });

    const link = findFirstLinkInEditor(editorState);

    const hideLinkPreview = link ? link[0].hideLinkPreview : undefined;
    const referrerPolicy = link ? link[0].referrerPolicy : undefined;

    let newEditorState = editorStateSettingLink(editorState, entityRange, {
      explicit: false,
      url,
      targetOption: '_blank',
      hideLinkPreview,
      referrerPolicy,
    });

    // reset selection to the initial cursor to avoid selecting the entire links
    newEditorState = EditorState.acceptSelection(newEditorState, selection);

    return newEditorState;
  }

  return editorState;
};

export const findFirstLinkInBody = (
  body?: RawDraftContentStateFragment
): ILink | undefined => {
  if (!body) {
    return undefined;
  }

  for (let i = 0; i < body.blocks.length; i++) {
    const block = body.blocks[i];
    for (let j = 0; j < block.entityRanges.length; j++) {
      const { key } = block.entityRanges[j];
      const entity: RawDraftEntity = body.entityMap[key];
      if (
        entity.type === DraftjsEntityType.LINK &&
        // when copy/pasted, at mentions will be link entities/stored as links in mongo
        typeof entity.data.explicit === 'boolean'
      ) {
        return [
          {
            key: String(key),
            hideLinkPreview: entity.data.hideLinkPreview,
            referrerPolicy: entity.data.referrerPolicy,
          },
          {
            url: entity.data.url,
            targetOption: entity.data.targetOption,
          },
        ];
      }
    }
  }

  return undefined;
};

export const findFirstLinkInEditor = (
  editorState: EditorState
): ILink | undefined => {
  if (!editorState) {
    return undefined;
  }

  const contentState = editorState.getCurrentContent();
  let firstLink: ILink | undefined;

  contentState.getBlockMap().forEach((block) => {
    block?.findEntityRanges(
      (character) => {
        const key = character.getEntity();
        if (
          key &&
          contentState.getEntity(key).getType() === DraftjsEntityType.LINK &&
          !firstLink &&
          // when copy/pasted, at mentions will be link entities/stored as links in mongo
          typeof contentState.getEntity(key).getData().explicit === 'boolean'
        ) {
          const data = contentState.getEntity(key).getData();
          firstLink = [
            {
              key: String(key),
              hideLinkPreview: data.hideLinkPreview,
              referrerPolicy: data.referrerPolicy,
            },
            {
              url: data.url,
              targetOption: data.targetOption,
            },
          ];
        }

        return false;
      },
      () => {}
    );
  });

  return firstLink;
};

export const createLinkPlugin = () => {
  return {
    decorators: [LinkDecorator],
    onChange: handleEditorStateChange,
  };
};
