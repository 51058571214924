import { gql, useApolloClient } from '@apollo/client';

import { EPrepImagesSource } from '@/graphql-types/globalTypes';
import { useImageUpload } from '@/hooks/useImageUpload';

import {
  UseHandlePastedFiles_GetDraftImageProxy,
  UseHandlePastedFiles_GetDraftImageProxyVariables,
} from './graphql-types/UseHandlePastedFiles_GetDraftImageProxy';

const GET_DRAFT_IMAGE_PROXY = gql`
  query UseHandlePastedFiles_GetDraftImageProxy(
    $input: GetDraftImageProxyInput!
  ) {
    getDraftImageProxy(input: $input) {
      imageId
      imageProxy(input: {})
    }
  }
`;

export const useHandlePastedFiles = () => {
  const client = useApolloClient();
  const { uploadImages, isUploading } = useImageUpload(
    EPrepImagesSource.BLOG_EMBEDDED_IMAGE
  );

  const getUrlsFromFiles = async (files: File[]): Promise<string[]> => {
    const imageIds = await uploadImages(files);

    const results = await Promise.all(
      imageIds.map(
        async (imageId) =>
          await client.query<
            UseHandlePastedFiles_GetDraftImageProxy,
            UseHandlePastedFiles_GetDraftImageProxyVariables
          >({
            query: GET_DRAFT_IMAGE_PROXY,
            variables: {
              input: {
                imageId,
                source: EPrepImagesSource.BLOG_EMBEDDED_IMAGE,
              },
            },
          })
      )
    );

    return results
      .map((result) => result.data.getDraftImageProxy?.imageProxy ?? '')
      .filter((v) => v !== '');
  };

  return {
    getUrlsFromFiles,
    isUploading,
  };
};
