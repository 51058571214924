import {
  Button,
  ButtonProps,
  chakra,
  useFormControlContext,
} from '@chakra-ui/react';
import * as React from 'react';

import { ChevronDownIcon } from '@/imports/ui/chakra/feather';

/**
 * This is a button that is styled like a chakra "Select" for use as a popover or menu trigger
 */
export const SelectButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    const formControlContext = useFormControlContext();
    const isDisabled = formControlContext?.isDisabled || props.isDisabled;

    return (
      <Button
        variant="outline"
        colorScheme="gray"
        backgroundColor="white"
        fontWeight="normal"
        rightIcon={<ChevronDownIcon />}
        justifyContent="space-between"
        borderColor={formControlContext?.isInvalid ? 'red.500' : undefined}
        border={formControlContext?.isInvalid ? '2px' : undefined}
        _hover={{ backgroundColor: 'white' }}
        w="full"
        isDisabled={isDisabled}
        _expanded={{ bg: 'gray.200' }}
        {...props}
        ref={ref}
        whiteSpace="normal"
        textAlign="left"
      >
        <chakra.span noOfLines={1} wordBreak="break-all" flex="1">
          {children}
        </chakra.span>
      </Button>
    );
  }
);

SelectButton.displayName = 'SelectMenuButton';
