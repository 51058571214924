import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

export const NumberedListIcon: React.FC<IconProps> = (props) => (
  <Icon
    width="1em"
    height="1em"
    viewBox="0 0 14 15"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path
      d="M4.66699 3.86523H12.2503"
      stroke="gray.700"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66699 7.36523H12.2503"
      stroke="gray.700"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66699 10.8652H12.2503"
      stroke="gray.700"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.22307 4.61523H1.81165V3.82112C1.81165 3.72467 1.8137 3.63644 1.81781 3.55641C1.7925 3.58719 1.76104 3.61934 1.72342 3.65285L1.5531 3.79341L1.34277 3.53487L1.85782 3.11523H2.22307V4.61523Z"
      fill="gray.700"
    />
    <path
      d="M2.32763 8.11523H1.25446V7.85225L1.61556 7.48712C1.71873 7.37923 1.78616 7.30573 1.81785 7.26662C1.84954 7.22683 1.87146 7.19278 1.88359 7.16446C1.89641 7.13614 1.90281 7.10647 1.90281 7.07545C1.90281 7.03701 1.89034 7.00667 1.86539 6.98442C1.84044 6.96217 1.80537 6.95104 1.7602 6.95104C1.71367 6.95104 1.66647 6.96453 1.61859 6.9915C1.57139 7.0178 1.51778 7.05691 1.45777 7.10883L1.23828 6.85192C1.31448 6.78381 1.37854 6.73526 1.43046 6.70627C1.48238 6.6766 1.53869 6.65401 1.59937 6.6385C1.66074 6.62299 1.72952 6.61523 1.80571 6.61523C1.90146 6.61523 1.98676 6.63209 2.06161 6.66581C2.13713 6.69952 2.19546 6.74774 2.2366 6.81045C2.2784 6.87248 2.29931 6.94227 2.29931 7.01982C2.29931 7.07781 2.29189 7.13142 2.27705 7.18064C2.26289 7.22987 2.24064 7.27842 2.2103 7.32629C2.17995 7.37349 2.13949 7.42339 2.08892 7.47599C2.03902 7.52859 1.93214 7.62838 1.76829 7.77538V7.7855H2.32763V8.11523Z"
      fill="gray.700"
    />
    <path
      d="M2.26076 10.4526C2.26076 10.543 2.23315 10.6209 2.17793 10.6861C2.12271 10.7506 2.04287 10.7975 1.93841 10.8268V10.8328C2.18192 10.8634 2.30368 10.9785 2.30368 11.1781C2.30368 11.3138 2.24979 11.4206 2.142 11.4985C2.03488 11.5763 1.88585 11.6152 1.6949 11.6152C1.61639 11.6152 1.54353 11.6096 1.47633 11.5983C1.4098 11.587 1.33761 11.5663 1.25977 11.5364V11.209C1.32364 11.2416 1.38851 11.2659 1.45438 11.2819C1.52091 11.2972 1.58212 11.3049 1.63801 11.3049C1.7245 11.3049 1.78738 11.2929 1.82663 11.2689C1.86655 11.2443 1.88651 11.2061 1.88651 11.1542C1.88651 11.1142 1.8762 11.0836 1.85557 11.0623C1.83495 11.0404 1.80168 11.0241 1.75577 11.0134C1.70987 11.0021 1.64999 10.9965 1.57613 10.9965H1.48631V10.7001H1.57813C1.7784 10.7001 1.87853 10.6488 1.87853 10.5464C1.87853 10.5078 1.86356 10.4792 1.83362 10.4605C1.80434 10.4419 1.76476 10.4326 1.71486 10.4326C1.62171 10.4326 1.52523 10.4639 1.42543 10.5264L1.26176 10.2629C1.33894 10.2097 1.41778 10.1718 1.49829 10.1492C1.57946 10.1265 1.67227 10.1152 1.77673 10.1152C1.92776 10.1152 2.04619 10.1452 2.13202 10.2051C2.21785 10.2649 2.26076 10.3474 2.26076 10.4526Z"
      fill="gray.700"
    />
  </Icon>
);
