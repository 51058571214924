import { gql, useMutation } from '@apollo/client';

import { EPrepImagesSource } from '@/graphql-types/globalTypes';
import { useUploadFileToS3 } from '@/hooks/useUploadFileToS3';
import { logError } from '@/imports/logging/ClientLogger';

import {
  ImageUpload_UploadImage,
  ImageUpload_UploadImageVariables,
} from './graphql-types/ImageUpload_UploadImage';

const IMAGE_UPLOAD_MUTATION = gql`
  mutation ImageUpload_UploadImage($input: PrepImagesAtSourceInput!) {
    prepImagesAtSource(input: $input) {
      results {
        presignedS3Url
        imageFileId
        metadataHeaders {
          name
          value
        }
      }
    }
  }
`;

export const useImageUpload = (
  source: EPrepImagesSource
): {
  uploadImages: (files: File[]) => Promise<string[]>;
  isUploading: boolean;
  numUploading: number;
  uploadProgress?: number;
} => {
  const [prepImageUpload] = useMutation<
    ImageUpload_UploadImage,
    ImageUpload_UploadImageVariables
  >(IMAGE_UPLOAD_MUTATION);

  const [uploadFileToS3, { isUploading, uploadProgress, numUploading }] =
    useUploadFileToS3();

  const uploadImages = async (files: File[]): Promise<string[]> => {
    try {
      const { data } = await prepImageUpload({
        variables: {
          input: { source, numImages: files.length },
        },
      });

      if (!data?.prepImagesAtSource) {
        throw Error('Image upload prep failed');
      }

      if (data?.prepImagesAtSource.results.length !== files.length) {
        throw new Error(`failed to prep for image upload`);
      }

      const promisedImageFileIds = data?.prepImagesAtSource.results.map(
        async (result, index) => {
          const { metadataHeaders, presignedS3Url, imageFileId } = result;

          const newMetadataHeaders: Record<string, string> = {};

          metadataHeaders?.forEach(({ name, value }) => {
            if (name && value) {
              newMetadataHeaders[name] = value;
            }
          });

          await uploadFileToS3({
            file: files[index],
            presignedS3Url,
            metadataHeaders: newMetadataHeaders,
          });

          return imageFileId;
        }
      );

      const imageFileIds = await Promise.all(promisedImageFileIds);
      return imageFileIds;
    } catch (err) {
      logError(err);
      return [];
    }
  };

  return { uploadImages, isUploading, uploadProgress, numUploading };
};
